<template>
  <div>
    <v-footer padless color="brown darken-4">
      <v-col class="text-center" cols="12">
        <v-card-text class="py-0 white--text text-center">
          <strong color="white--text"
            >กรมป่าไม้ กระทรวงทรัพยากรธรรมชาติและสิ่งแวดล้อม</strong
          >
        </v-card-text>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
