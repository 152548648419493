<template>
  <div>
    <!-- <div class="bg"> -->
    <!-- <v-toolbar
        flat
        height="50"
        color="transparent"
        class="d-flex justify-center"
      >
        <v-toolbar-title class="white--text font-c-bold">
          <span class="font-weight-bold fs-2em">พฤกษามหามงคล</span>
        </v-toolbar-title>
      </v-toolbar> -->
    <v-app-bar
      flat
      height="60"
      color="#4a2311"
      fixed
      dense
      scroll-target="#scroll"
      class="bg justify-space-between"
    >
      <v-toolbar-title
        class="white--text font-c-bold"
        @click="goto()"
        style="cursor:pointer;"
      >
        <span
          class="font-weight-bold fs-1_5em d-none d-md-block"
          style="margin-bottom: 1px;"
          >พฤกษามหามงคล</span
        >
      </v-toolbar-title>
      <v-toolbar-title
        class="white--text font-c-bold"
        @click="goto()"
        style="cursor:pointer;"
      >
        <span
          class="font-weight-bold fs-22 d-md-none"
          style="margin-bottom: 1px;"
          >พฤกษามหามงคล</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div
        v-for="(i, index) in listMenu"
        :key="index"
        class="mx-v d-none d-md-block"
      >
        <v-btn
          depressed
          :text="i.id == 6 ? false : true"
          rounded
          :color="i.id == 6 ? 'primary' : ''"
          class="white--text font-weight-bold mx-2 d-flex justify-center align-center"
          @click="scroll(i.link)"
        >
          <span class="fs-16">{{ i.title }}</span>
        </v-btn>
      </div>
      <div class="text-center d-md-none">
        <v-menu offset-y transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              text
              dark
              v-bind="attrs"
              v-on="on"
              class="white--text"
              block
            >
              <v-icon class="mr-2">mdi-menu</v-icon
              ><span class="font-weight-bold fs-16">เมนู</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in listMenu" :key="index">
              <v-list-item-title class="align-center">
                <v-btn
                  depressed
                  :text="item.id == 6 ? false : true"
                  rounded
                  :color="item.id == 6 ? 'primary' : ''"
                  class="subtitle-1 d-flex justify-center align-center"
                  @click="scroll(item.link)"
                >
                  {{ item.title }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- </div> -->
    <v-sheet id="scroll" class="overflow-y-auto" style="padding-top: 60px">
      <v-main>
        <v-container fluid fill-height class="pa-0 ma-0 box">
          <router-view> </router-view>
        </v-container>
      </v-main>
    </v-sheet>
    <footer2></footer2>
  </div>
</template>
<script>
import footer2 from "../views/Footer.vue";

export default {
  components: { footer2 },
  data() {
    return {
      height: "calc(100vh-80px)",
      listMenu: [
        {
          id: 2,
          title: "ขั้นตอนการขอรับกล้าไม้",
          link: "howto"
        },
        {
          id: 4,
          title: "กล้าไม้มงคล 10 ชนิด",
          link: "tree"
        },
        {
          id: 3,
          title: "หน่วยงานแจกกล้าไม้",
          link: "map"
        },

        {
          id: 5,
          title: "FAQ",
          link: "faq"
        },
        {
          id: 7,
          title: "สถิติ",
          link: "report"
        },
        {
          id: 6,
          title: "จองกล้าไม้",
          link: "reserve"
        }
      ]
    };
  },
  methods: {
    scroll(link) {
      // console.log(link);
      if (this.$route.path !== "/" + link) {
        if (link === "map" || link === "faq") {
          this.$router.push(link);
        } else if (this.$route.path === "/map" || this.$route.path === "/faq") {
          // console.log(this.$route.path);
          if (link === "tree" || link === "howto" || link === "/") {
            this.$router.push("/");
            this.scrollTo(link);
          } else if (link === "reserve") {
            window.open(
              "https://fp.forest.go.th/rfd_app/rfd_nursery/app/event_tree_search.php"
            );
          } else if (link === "report") {
            window.open(
              "https://fp.forest.go.th/rfd_app/rfd_nursery/app/special_event_dashboard.php"
            );
          }
        } else if (link === "reserve") {
          window.open(
            "https://fp.forest.go.th/rfd_app/rfd_nursery/app/event_tree_search.php"
          );
        } else if (link === "report") {
          window.open(
            "https://fp.forest.go.th/rfd_app/rfd_nursery/app/special_event_dashboard.php"
          );
        } else {
          let firstError = null;
          firstError = document.getElementById(link);
          this.$scrollTo(firstError, { offset: -100 });
        }
      }
    },
    scrollTo(link) {
      let first = null;
      this.$nextTick(() => {
        first = document.getElementById(link);
        this.$scrollTo(first, { offset: -100 });
      });
    },
    goto() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    }
  }
};
</script>

<style lang="scss">
.bg {
  // background-image: url("../assets/images/bg.jpg");
  background-color: #4a2311;
  background-size: cover;
  background-position: center;
}

.box {
  height: calc(100% - 80px);
}
</style>
